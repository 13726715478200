.note-preview-main a {
	color: #4f4fda !important;
	text-decoration: underline !important;
}

.note-preview-main blockquote {
	display: block !important;
	margin-block-start: 1em !important;
	margin-block-end: 1em !important;
	margin-inline-start: 40px !important;
	margin-inline-end: 40px !important;
}

.note-preview-main h1,
h2,
h3,
h4,
h5,
h6 {
	margin-block-start: 0.67em !important;
	margin-block-end: 0.67em !important;
	margin-inline-start: 0 !important;
	margin-inline-end: 0 !important;
	font-weight: bold !important;
}

.note-preview-main h1 {
	font-size: 2em !important;
}

.note-preview-main h2 {
	font-size: 1.5em !important;
}

.note-preview-main h3 {
	font-size: 1.17em !important;
}
